body {
    background:'black';
    max-width:100%;
    overflow-x:hidden;
}
.cityTitle {
    font-family: 'Playfair Display', serif;
    font-style: italic;
    opacity:0.15;
    transition:25ms all;
}
.cityTitle.activeCity {
    opacity:1;
}
.CityFigure {
    opacity:0;
    transition-delay:500ms text-shadow, 10s opacity;
    font-family: 'IBM Plex Mono', monospace;
}
.serifH2 {
    font-family: 'Playfair Display', serif;
    font-style: italic;
}
.CityFigure.activeCity {
    opacity:1;
    transition:250ms text-shadow, 150ms opacity;
    // animation: textShadowFade ease-in-out infinite 3s;
}
@keyframes textShadowFade {
    0% {text-shadow: 5px 5px 0px rgb(0,0,0), -5px -5px 0px rgb(0,0,0);}
    50% { text-shadow: -5px 5px 0px rgb(0,0,0), 5px -5px 0px rgb(0,0,0);}
    100% {text-shadow: -5px -5px 0px rgb(0,0,0), 5px 5px 0px rgb(0,0,0);}
}
.hidden {
    opacity:0;
}
svg text {
    color:white;
    font-size:1rem;
}
svg {
    overflow:visible;
}
.activatedPct {
    opacity:0.5;
}
.activeButton {
    opacity:1;
    transition:250ms all;
}
.hiddenButton {
    opacity:0;
    transition:250ms all;
    pointer-events: none;
}
.openDrawer, .closeDrawer {
    left:20px;
    top:20px;
    padding:20px;
    position:fixed !important;
    transition:250ms all;
    z-index:9;
}
@media (max-width:1280px){
    .openDrawer, .closeDrawer {
        z-index:3;
    }
}
.openDrawer::after {
    content:"≡";
    z-index: 9;
    color:'red';
    background:'red';
    width:20px;
    height:20px;
    transition:250ms all;
    position:fixed;
    left:20px;
}
.closeDrawer {
    left:120px;
    position:fixed;
    transition:250ms all;
    z-index:9;
}
.closeDrawer::after {
    content:"×";
    position:fixed;
    left:120px;
    z-index: 9;
    color:'red';
    background:'red';
    width:20px;
    height:20px;
    transition:250ms all;
    text-shadow:0px 0px 5px black;
    transform:translateX(10px)
}

@media (max-width:960px){
    .openDrawer::after, .closeDrawer::after{
        font-size:40px;
    }

}
.drawer div {
    background:rgba(0,0,0,0);
    padding:20px;
    border:none;
}
.drawer div button span{
    text-align:left;
    float:left;
}

.InfoContainer {
    position:fixed;
    top:0;
    left:0;
    z-index: 3;
    background:rgb(20,20,18);
    min-height:100%;
    animation: fadeIn 500ms ease-in 1;
    padding:3vw;
    height: 100%;
    width:100%;
    overflow-x: hidden;
    overflow-y: scroll;
}
@media (max-width:960px) {
    .InfoContainer {
        padding-bottom:20vh;
    }
}
@keyframes fadeIn {
    from{opacity:0}
    to{opacity:1}
}
#dotArray {
    position:fixed;
    height:50%;
    z-index:3;
    width:40px;
    right:15%;
    top:57%;
    transform:translateY(-50%);
}
@media (max-width:1024px){
    #dotArray {
        height:30%;
        right:10%;
    }
}
@media (max-width:768px) {
    #dotArray {
        right:0;
    }
}
#dotArray .infoDot {
    fill:white;
    transition:250ms all;
    transition-timing-function: ease-out;
    opacity:0.5;
}
#DotTooltip {
    position:absolute;
    font-size:10px;
    color:white;
    z-index:10;
}
.hiddenContainer {
    z-index: 0;
    opacity:0;
}
.custom-tooltip {
    background:white;
    color:black;
    padding:2px 10px;
    box-shadow: 0px 0px 2px rgba(0,0,0,0.5);
}
.infoBox {
    position:fixed;
    width:50vw;
    max-width: 500px;
    z-index: 100;
    background:white;
    color:black;
    text-align:left;
    padding:10px;
    top:25px;
    left:-100%;
    transition:250ms all;
}
.infoBox.active {
    left:160px;
}
@media (max-width:600px) {
    .infoBox {
        width:90%;
        bottom:20px !important;
        top:initial !important;
        max-height:70vh;
        overflow-y:scroll;
    }
    .infoBox.active {
        left:5vw;
    }
}
@media (max-width:400px) {
    .infoBox {
        max-height:50vh;
        overflow-y:scroll;
    }
}
a, a:visited {
    color:white;
    transition:250ms color;
}
a.lightLink, a.LightLink:visited {
    color:black;
}
a:hover {
    color:#0099ff !important;
}
.socialImg {
    display:inline;
    height:2.4vw;
    transform: translateY(13%);
    transition:250ms all;
    opacity:0.8;
}
.socialImg:hover {
    opacity:1;
    transform:translateY(13%) scale(1.2);
}
#toggleButtons {
    position:fixed;
    bottom:45%;
    right:3vw;
    width:30px;
    z-index:14;
}
#toggleButtons button {
}
#toggleButtons svg {
    display:inline;
    fill:none;
    stroke:white;
    stroke-width: 1px;
    width:1.5vw;
    height:1.5vw;
    transition:250ms all;
    margin:5px 0;
    opacity:0.5;
}
#toggleButtons svg.active {
    fill:white;
    stroke:none;
    opacity:1;
}
@media (max-width:960px){
    .socialImg {
        height:5vw;
    }
}
@media (max-width:600px){
    .socialImg {
        height:7vw;
    }
}
